@import "cores";

@media (min-width: 769px) {
  #principal {
    display: block;
  }
}

@media (max-width: 950px) {
  #principal {
    position: fixed;
    right: -100%;
    top: 87px;
    width: 40vw;
    height: 100%;
    background-color: #fff;
    transition: 1s;
    text-align: left;
    padding: 30px;
    &.ativo {
      right: 0;
    }
    li {
      float: unset;
      padding: 5px 0;
      &.ativa a {
        color: $corPrimaria;
      }
      a {
        padding: 5px 0;
        font-size: 1.2rem;
      }
    }
  }
  header {
    background-color: $corPrimaria;
    #logo-header {
      width: 100px;
    }
    .inner-header {
      padding: 10px 0;
    }
    .search {
      a {
        font-size: 2rem;
      }
      .dd-select {
        border: none;
        width: 60px !important;
      }
      .dd-container {
        position: relative;
        margin: 4px 15px 0;
      }
    }
    #mobile-toggle {
      display: block;
    }
  }
}
@media (min-width: 501px) {
  .d-none {
    display: none;
  }
  .m-none {
    display: block;
  }
}

@media only screen and (max-width: 500px) {

  header{
    a{
      &:hover{
        color: $azul !important;
      }
    }
  }
  div#slide-depoimentos {
    margin-bottom: 12px;
  }
  #depoimentos{
    padding-bottom: 10px !important;
  }
  #formulario input,
  #formulario textarea {
    width: 100%;
    max-width: 90%;
  }
  .img-banner {
    margin-top: 80px;
    .nav-gallery {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;
      .slick-disabled {
        opacity: 0.5;
        cursor: auto;
      }
      .prev {
        position: absolute;
      left: 35px;
      pointer-events: all;
      cursor:pointer;
      transform:scale(.5);
      }
      .next {
        position: absolute;
        right:35px;
        transform: rotate(180deg) scale(.5);
        pointer-events: all;
        cursor:pointer
      }
    }

  }
  .d-none {
    display: block;
  }
  .m-none {
    display: none !important;
  }
  .container {
    width: 95vw;
  }
  header {
    background-color: $corPrimaria;
    #logo-header {
      width: 100px;
    }
    .inner-header {
      padding: 10px 0;
    }
    .search {
      a {
        font-size: 2rem;
      }
      .dd-select {
        border: none;
        width: 60px !important;
      }
      .dd-container {
        position: relative;
        margin: 4px 15px 0;
      }
    }
    #mobile-toggle {
      display: block;
    }
  }
  .info-empresa {
    padding: 6px 25px;
    h3 {
      font-weight: 700;
      text-transform: none;
      font-style: normal;
      font-size: 12px;
    }
    p {
      font-size: 10px;
      margin: 0 0 3px;
    }
    a {
      color: #19315b;
      text-decoration: none;
      font-size: 12px;
    }
  }
  footer {
    .inner-footer {
      ul.social {
        text-align: center;
      }
    }
  }

  #scroll-top {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10%;
    top: 10%;
    color: #fff;
    font-size: 20px;
    border-radius: 100px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: $corPrimaria;
    }
  }
  .plyr__control--overlaid {
    transform: scale(2) translate(-30%, -30%);
  }

  #produtos {
    .item {
      border-radius: 25px;
      overflow: hidden;
      margin: 10px 0;
      height: 100%;
      min-height: unset;
      a {
        float: right;
      }
      >.row{
        display: flex;
      }
      .footer-card{
        right:0;
        transform: scale(.8) translateY(12px)
      }
    }
  }
  #infos-imprensa {
    p {
      strong {
        display: block;
      }
      color: $corPrimaria;
    }
  }
  #feed .btn-outline {
    padding: 5px 15px;
    font-size: 0.8rem;
  }
  footer {
    border-radius: 0;
  }
  #busca-autorizados{
    h2{
      white-space: normal;
    }
  }
  .remodal {
    max-width: 90vw;
    padding: 0 10px 25px;
    border-radius: 30px;
    border: 1px solid $azul;
    background-color: #ebebeb;
    textarea {
      width: 87%;
      background-color: transparent;
      border-radius: 25px;
    }
  }
  .navegacao {
    a {
      font-size: 12px !important;
      padding: 10px;
    }
  }
}
